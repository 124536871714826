import Link, { LinkProps } from '~/components/UI/Link'

function WrapperWithLink({ ...props }: LinkProps) {
  return props?.href ? (
    <Link {...props}>{props?.children}</Link>
  ) : (
    <div className={props?.className}>{props?.children}</div>
  )
}

WrapperWithLink.defaultProps = {}

export default WrapperWithLink
