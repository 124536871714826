import classnames from 'classnames/bind'
import React from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextAlign } from '~/@types/text-align'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextSize } from '~/@types/text-size'
import { GlobalTextStyling } from '~/@types/text-styling'

import { useSliderState } from '@unlikelystudio/react-slider'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import { SliderNavigation } from '~/components/Slices/ProductsSlider'
import InlineCta, { InlineCtaProps } from '~/components/UI/InlineCta'
import SliderCover, { SliderCoverItemProps } from '~/components/UI/SliderCover'
import SquaredCta, { SquaredCtaProps } from '~/components/UI/SquaredCta'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingPromotionImpression from '~/hooks/useTrackingPromotionImpression'
import useTruncatedString from '~/hooks/useTruncatedString'

import getCSSThemeClassName from '~/utils/get-css-theme-classname'

import { GENERAL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const TITLE_SIZES = {
  [GlobalTextSize.Big]: GlobalTextPreset.Titlefluid45_150HafferSemiBold,
  [GlobalTextSize.Small]: GlobalTextPreset.Title30_60HafferSemiBold,
}

export enum TitleLayout {
  LAYOUT_1 = 'title-on-image',
  LAYOUT_2 = 'title-half-image',
  LAYOUT_3 = 'title-below-image-edito-right',
}

interface HeroMainTexts {
  title?: RichTextBlocks
  titleTheme?: GlobalThemeColors.Black | GlobalThemeColors.White
  subtitle?: RichTextBlocks
  description?: RichTextBlocks
  cta?: InlineCtaProps | SquaredCtaProps
}

interface HeroBottomTexts {
  items?: HeroMainItem[]
  titleSize?: GlobalTextSize
  slideIndex: number
}

export function HeroBottomTexts({
  titleSize,
  items,
  slideIndex,
}: HeroBottomTexts) {
  const titleStyle = useStyle({
    textPreset: TITLE_SIZES[titleSize],
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <>
      <div className={cx(css.header)}>
        <div className={cx(css.titles)}>
          {items?.map(({ title, titleTheme }, index) => {
            const titleColorClass = getCSSThemeClassName(
              css,
              'theme',
              titleTheme,
            )

            return (
              index === slideIndex && (
                <RichText
                  key={`title_${index}`}
                  render={title}
                  className={cx(css.title, titleStyle, titleColorClass)}
                />
              )
            )
          })}
        </div>
      </div>

      {items?.length > 0 && (
        <div className={css.texts}>
          {items?.map(
            (item, index) =>
              index == slideIndex && (
                <div className={cx(css.text)} key={`item_${index}`}>
                  <HeroMainTexts {...item} />
                </div>
              ),
          )}
        </div>
      )}
    </>
  )
}

export function HeroMainTexts({ subtitle, description, cta }: HeroMainTexts) {
  const t = useTranslate()
  const {
    needTruncate,
    text: processedText,
    expanded,
    setExpanded,
  } = useTruncatedString(description)

  const subtitleStyle = useStyle({
    textPreset: GlobalTextPreset.Title18_24HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const descriptionStyle = useStyle({
    textPreset: GlobalTextPreset.Text12_14Haffer,
    color: GlobalThemeColors.MineShaft,
  })

  return (
    <>
      <RichText className={cx(css.subtitle, subtitleStyle)} render={subtitle} />
      {processedText && (
        <div className={cx(css.description, descriptionStyle)}>
          {!needTruncate && (
            <RichText
              ctaPreset={GlobalTextPreset.Cta12_14Haffer}
              uppercaseLink={false}
              render={processedText}
            />
          )}
          {needTruncate && (
            <>
              {expanded && (
                <RichText
                  ctaPreset={GlobalTextPreset.Cta12_14Haffer}
                  uppercaseLink={false}
                  render={processedText}
                />
              )}
              {!expanded && (
                <>
                  <div>{processedText}</div>
                  <InlineCta
                    textPreset={GlobalTextPreset.Cta12_14HafferSemiBold}
                    textStyling={GlobalTextStyling.UpperCase}
                    className={css.discoverMore}
                    href={null}
                    onClick={() => setExpanded(true)}>
                    {t(GENERAL.DISCOVER_MORE)}
                  </InlineCta>
                </>
              )}
            </>
          )}
        </div>
      )}

      {cta && (
        <div className={cx(css.cta)}>
          <SquaredCta
            className={cx(css.ctaLabel)}
            {...cta}
            theme={GlobalThemeColors.Black}
            textPreset={GlobalTextPreset.Cta12Haffer}
          />
        </div>
      )}
    </>
  )
}

type HeroMainItem = HeroMainTexts & SliderCoverItemProps
export interface HeroMainProps {
  className?: string
  items?: HeroMainItem[]
  titleLayout: TitleLayout
  textAlign?: GlobalTextAlign
  titleSize?: GlobalTextSize
  isFirstSlice?: boolean
}

function HeroMain({
  className,
  items,
  titleLayout = TitleLayout.LAYOUT_1,
  titleSize = GlobalTextSize.Big,
  isFirstSlice,
}: HeroMainProps) {
  // Getting the slice position
  const { position: slicePosition } = useSliceProvider()

  const { ref } = useTrackingPromotionImpression()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleSizeStyle = getCSSThemeClassName(css, 'titleSize', titleSize)
  const titleLayoutStyle = getCSSThemeClassName(css, 'titleLayout', titleLayout)

  const [{ slideIndex, prevSlide, nextSlide }, setSliderState] =
    useSliderState()

  return (
    <div
      ref={ref}
      className={cx(
        css.HeroMain,
        className,
        gridStyle,
        titleLayoutStyle,
        titleSizeStyle,
        { hasSlider: items?.length > 1 },
      )}>
      <div className={cx(css.items, css.containerSliderCover)}>
        {items?.length > 0 && (
          <SliderCover
            setSliderState={setSliderState}
            items={items}
            hasSlicePriority={
              isFirstSlice ? true : slicePosition ? slicePosition === 1 : false
            }
          />
        )}
        {items?.length > 1 && (
          <SliderNavigation
            className={cx(css.sliderNavigation)}
            iconClassName={css.iconClassName}
            isPrevVisible={true}
            isNextVisible={true}
            onPrev={prevSlide}
            onNext={nextSlide}
          />
        )}
      </div>

      {titleLayout === TitleLayout.LAYOUT_3 && items?.length > 0 && (
        <div className={css.bottomContainer}>
          <HeroBottomTexts
            titleSize={titleSize}
            items={items}
            slideIndex={slideIndex}
          />
        </div>
      )}

      {titleLayout !== TitleLayout.LAYOUT_3 && items?.length > 0 && (
        <HeroBottomTexts
          titleSize={titleSize}
          items={items}
          slideIndex={slideIndex}
        />
      )}
    </div>
  )
}

export default HeroMain
